.scene {
    width: 200px;
    height: 200px;
    perspective: 400px;
}

.cube {
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px);
    transition: transform 1s;
}

.cube.show-front  { transform: translateZ(-100px) rotateY(0deg); }
.cube.show-left   { transform: translateZ(-100px) rotateY(90deg); }

.cube__face {
    position: absolute;
}

.cube__face--front  { background: white }
.cube__face--left   { background: #293c50 }

.cube__face--front  { transform: rotateY(  0deg) translateZ(100px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(100px); }

.scene:hover .cube {
    transform: translateZ(-100px) rotateY(90deg);
}