
.navbar-tabs {
    position: relative;
    display: inline-block;
}

.navbar-tabs::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    min-height: 2px;
    bottom: 0;
    left: 0;
    border-radius: 0.25rem;
    background-color: black;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 0.3s ease-out;
}

.navbar-tabs:hover::before {
    transform: scaleY(1);
}